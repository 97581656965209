<template>
  <!-- 商品菜单 -->
  <SideBar :groups='groups'></SideBar>
</template>

<script>
import SideBar from "@/components/SideBar";
export default {
  components: {
    SideBar,
  },
  
  data() {
    return {
      groups: [
        {
          label: "个性装修",
          list: [
            {
              name: "店铺导航",
              path: "/mall/store/navigation-tpl",
            },
          ],
        },
        {
          label: "系统页面",
          list: [
            // {
            //   name: "列表模板",
            //   path: "/mall/store/list-tpl",
            // },
            // {
            //   name: "分类模板",
            //   path: "/mall/store/category-tpl",
            // },
            {
              name: "搜索页",
              path: "/mall/store/search-tpl",
            },
          ],
        },
      ],
    };
  },
};
</script>